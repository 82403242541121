import { ResolveFn, Router } from '@angular/router';
import { RouteInfos } from './route-info.type';
import { inject } from '@angular/core';

/**
 * Kotelezo `hasRouteInfoCanActivateGuard` -val egyutt hasznalni!
 * Ha nem igy jarunk el akkor biztositani kell, hogy a state info fel legyen toltve amire a kod feldolgozas ide er
 */
export function routeInfoResolver(): ResolveFn<RouteInfos> {
  return () => {
    return inject(Router).getCurrentNavigation()?.extras?.['info'] as RouteInfos;
  };
}
