import {
  BaseRouteInfo,
  EmptyAbleRouteInfo,
  NullableRouteInfo,
  RouteInfo,
  RouteInfoBoolean,
  RouteInfoBooleanArray,
  RouteInfoCallback,
  RouteInfoDate,
  RouteInfoDateArray,
  RouteInfoNumber,
  RouteInfoNumberArray,
  RouteInfos,
  RouteInfoString,
  RouteInfoStringArray,
  RouteInfoType,
  routeInfoTypes,
  ValueRouteInfo,
} from './route-info.type';
import {
  isBoolean,
  isFunction,
  isNil,
  isNumber,
  isString,
} from '@camino-solutions/utils/typeguard';
import { isDate } from 'date-fns';
import { Observable } from 'rxjs';

function isObjectWithBaseRouteInfo(info: unknown, expectedType: RouteInfoType): boolean {
  if (typeof info !== 'object' || isNil(info)) {
    return false;
  }
  if (!isBaseRouteInfo(info) || info.type !== expectedType) {
    return false;
  }
  return true;
}

function checkNullable(routeInfo: NullableRouteInfo): boolean {
  if (routeInfo.null !== undefined && !isBoolean(routeInfo.null)) {
    return false;
  }
  return true;
}

function checkEmptyAble(routeInfo: EmptyAbleRouteInfo): boolean {
  if (routeInfo.empty !== undefined && !isBoolean(routeInfo.empty)) {
    return false;
  }
  return true;
}

function validateArrayValue<T>(
  routeInfo: ValueRouteInfo<T> & EmptyAbleRouteInfo,
  elementCheck: (v: unknown) => boolean,
): boolean {
  if (!Array.isArray(routeInfo.value)) {
    return false;
  }
  if (isBoolean(routeInfo.empty) && routeInfo.empty && routeInfo.value.length === 0) {
    return true;
  }
  return routeInfo.value.every(elementCheck);
}

// Type-guard functions

export function isBaseRouteInfo(info: unknown): info is BaseRouteInfo {
  if (typeof info === 'object' && !isNil(info)) {
    const type = (info as Record<string, unknown>)['type'] as RouteInfoType;
    return isString(type) && routeInfoTypes.includes(type);
  }
  return false;
}

export function isRouteInfoWithCallbackValueType<R, T extends (...args: any[]) => Observable<R>>(
  info: unknown,
): info is RouteInfoCallback<R, T> {
  if (!isObjectWithBaseRouteInfo(info, 'callback')) {
    return false;
  }
  const routeInfo = info as RouteInfoCallback<R, T>;
  return isFunction(routeInfo.callback);
}

export function isRouteInfoWithStringValueType(info: unknown): info is RouteInfoString {
  if (!isObjectWithBaseRouteInfo(info, 'string')) {
    return false;
  }
  const routeInfo = info as RouteInfoString;
  if (!checkNullable(routeInfo)) {
    return false;
  }
  if (isBoolean(routeInfo.null) && routeInfo.null && routeInfo.value === null) {
    return true;
  }
  return isString(routeInfo.value);
}

export function isRouteInfoWithNumberValueType(info: unknown): info is RouteInfoNumber {
  if (!isObjectWithBaseRouteInfo(info, 'number')) {
    return false;
  }
  const routeInfo = info as RouteInfoNumber;
  if (!checkNullable(routeInfo)) {
    return false;
  }
  if (isBoolean(routeInfo.null) && routeInfo.null && routeInfo.value === null) {
    return true;
  }
  return isNumber(routeInfo.value);
}

export function isRouteInfoWithBooleanValueType(info: unknown): info is RouteInfoBoolean {
  if (!isObjectWithBaseRouteInfo(info, 'boolean')) {
    return false;
  }
  const routeInfo = info as RouteInfoBoolean;
  if (!checkNullable(routeInfo)) {
    return false;
  }
  if (isBoolean(routeInfo.null) && routeInfo.null && routeInfo.value === null) {
    return true;
  }
  return isBoolean(routeInfo.value);
}

export function isRouteInfoWithDateValueType(info: unknown): info is RouteInfoDate {
  if (!isObjectWithBaseRouteInfo(info, 'Date')) {
    return false;
  }
  const routeInfo = info as RouteInfoDate;
  if (!checkNullable(routeInfo)) {
    return false;
  }
  if (isBoolean(routeInfo.null) && routeInfo.null && routeInfo.value === null) {
    return true;
  }
  return isDate(routeInfo.value);
}

export function isRouteInfoWithStringArrayValueType(info: unknown): info is RouteInfoStringArray {
  if (!isObjectWithBaseRouteInfo(info, 'string[]')) {
    return false;
  }
  const routeInfo = info as RouteInfoStringArray;
  if (!checkNullable(routeInfo) || !checkEmptyAble(routeInfo)) {
    return false;
  }
  if (isBoolean(routeInfo.null) && routeInfo.null && routeInfo.value === null) {
    return true;
  }
  return validateArrayValue(routeInfo, isString);
}

export function isRouteInfoWithNumberArrayValueType(info: unknown): info is RouteInfoNumberArray {
  if (!isObjectWithBaseRouteInfo(info, 'number[]')) {
    return false;
  }
  const routeInfo = info as RouteInfoNumberArray;
  if (!checkNullable(routeInfo) || !checkEmptyAble(routeInfo)) {
    return false;
  }
  if (isBoolean(routeInfo.null) && routeInfo.null && routeInfo.value === null) {
    return true;
  }
  return validateArrayValue(routeInfo, isNumber);
}

export function isRouteInfoWithBooleanArrayValueType(info: unknown): info is RouteInfoBooleanArray {
  if (!isObjectWithBaseRouteInfo(info, 'boolean[]')) {
    return false;
  }
  const routeInfo = info as RouteInfoBooleanArray;
  if (!checkNullable(routeInfo) || !checkEmptyAble(routeInfo)) {
    return false;
  }
  if (isBoolean(routeInfo.null) && routeInfo.null && routeInfo.value === null) {
    return true;
  }
  return validateArrayValue(routeInfo, isBoolean);
}

export function isRouteInfoWithDateArrayValueType(info: unknown): info is RouteInfoDateArray {
  if (!isObjectWithBaseRouteInfo(info, 'Date[]')) {
    return false;
  }
  const routeInfo = info as RouteInfoDateArray;
  if (!checkNullable(routeInfo) || !checkEmptyAble(routeInfo)) {
    return false;
  }
  if (isBoolean(routeInfo.null) && routeInfo.null && routeInfo.value === null) {
    return true;
  }
  return validateArrayValue(routeInfo, isDate);
}

export function isRouteInfo(info: unknown): info is RouteInfo {
  if (typeof info !== 'object' || isNil(info)) {
    return false;
  }

  switch ((info as Record<string, unknown>)['type']) {
    case 'callback':
      return isRouteInfoWithCallbackValueType(info);
    case 'string':
      return isRouteInfoWithStringValueType(info);
    case 'number':
      return isRouteInfoWithNumberValueType(info);
    case 'boolean':
      return isRouteInfoWithBooleanValueType(info);
    case 'Date':
      return isRouteInfoWithDateValueType(info);
    case 'string[]':
      return isRouteInfoWithStringArrayValueType(info);
    case 'number[]':
      return isRouteInfoWithNumberArrayValueType(info);
    case 'boolean[]':
      return isRouteInfoWithBooleanArrayValueType(info);
    case 'Date[]':
      return isRouteInfoWithDateArrayValueType(info);
    default:
      return false;
  }
}

export function isRouteInfos(value: unknown): value is RouteInfos {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  return Object.values(value as Record<string, unknown>).every(isRouteInfo);
}
