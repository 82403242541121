import { CanActivateFn, RedirectCommand, Router } from '@angular/router';
import { inject } from '@angular/core';
import { RouteInfo } from './route-info.type';
import { isRouteInfos } from './route-info-type-guard';
import { isNil } from '@camino-solutions/utils/typeguard';
import { Observable, of, switchMap } from 'rxjs';
import { getMessageDialogService } from '@camino-solutions/core/message-dialog';

export type OmmitedRouteInfo = Omit<RouteInfo, 'value'>;
export interface HasRouteInfoCanActivateGuardParam {
  key: string;
  routeInfo: OmmitedRouteInfo;
}

function notFoundRouteInfo() {
  const translatePrefix = 'COMMON.MESSAGE_DIALOG.';

  return getMessageDialogService().open({
    type: 'TEXT',
    messageType: 'ERROR',
    header: `${translatePrefix}ERROR.HEADER`,
    content: `${translatePrefix}ROUTER.NOT_FOUND_ROUTE_INFO`,
  }).onClose;
}

function handleCheck(
  router: Router,
  result: boolean,
  checkKeys: HasRouteInfoCanActivateGuardParam | HasRouteInfoCanActivateGuardParam[],
): Observable<boolean | RedirectCommand> {
  if (result === false) {
    console.error('not found keys: ', checkKeys);
    notFoundRouteInfo().subscribe();
    if (router.lastSuccessfulNavigation === null) {
      return of(new RedirectCommand(router.parseUrl('/')));
    }
  }
  return of(result);
}

export function hasRouteInfoCanActivateGuard(
  checkKeys: HasRouteInfoCanActivateGuardParam | HasRouteInfoCanActivateGuardParam[],
): CanActivateFn {
  return () => {
    const router = inject(Router);

    checkKeys = Array.isArray(checkKeys) ? checkKeys : [checkKeys];
    const routeInfos = inject(Router).getCurrentNavigation()?.extras?.['info'];

    if (isRouteInfos(routeInfos)) {
      // Ellenőrzi, hogy minden `checkKey` megtalálható-e `routeInfos`-ban és típusazonos-e
      return of(
        checkKeys.every(({ key, routeInfo: checkRouteInfo }) => {
          const routeInfo = routeInfos[key];

          if (isNil(routeInfo)) {
            return false;
          }

          return Object.entries(checkRouteInfo).every(([propertyKey, propertyValue]) => {
            return routeInfo[propertyKey as keyof OmmitedRouteInfo] === propertyValue;
          });
        }),
      ).pipe(switchMap(result => handleCheck(router, result, checkKeys)));
    }

    return of(false).pipe(switchMap(result => handleCheck(router, result, checkKeys)));
  };
}
