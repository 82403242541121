import { Observable } from 'rxjs';

export const routeInfoTypes = [
  'string',
  'number',
  'boolean',
  'Date',
  'string[]',
  'number[]',
  'boolean[]',
  'Date[]',
  'callback',
] as const;

export type RouteInfoType = (typeof routeInfoTypes)[number];

export interface BaseRouteInfo {
  type: RouteInfoType;
}

export interface NullableRouteInfo {
  /**
   * Lehet null ertek
   */
  null?: boolean;
}

export interface EmptyAbleRouteInfo {
  /**
   * Lehet ures ertek(ez nem egyenlo az undefined es null ertekkel!)
   */
  empty?: boolean;
}

export interface ValueRouteInfo<T> {
  value: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface RouteInfoCallback<R = any, FT extends (...args: any[]) => Observable<R> = any>
  extends BaseRouteInfo {
  type: 'callback';
  callback: FT;
}

export interface RouteInfoString extends NullableRouteInfo, ValueRouteInfo<string>, BaseRouteInfo {
  type: 'string';
}

export interface RouteInfoNumber extends NullableRouteInfo, ValueRouteInfo<number>, BaseRouteInfo {
  type: 'number';
}

export interface RouteInfoBoolean
  extends NullableRouteInfo,
    ValueRouteInfo<boolean>,
    BaseRouteInfo {
  type: 'boolean';
}

export interface RouteInfoDate extends NullableRouteInfo, ValueRouteInfo<Date>, BaseRouteInfo {
  type: 'Date';
}

export interface RouteInfoStringArray
  extends NullableRouteInfo,
    EmptyAbleRouteInfo,
    ValueRouteInfo<string[]>,
    BaseRouteInfo {
  type: 'string[]';
}

export interface RouteInfoNumberArray
  extends NullableRouteInfo,
    EmptyAbleRouteInfo,
    ValueRouteInfo<number[]>,
    BaseRouteInfo {
  type: 'number[]';
}

export interface RouteInfoBooleanArray
  extends NullableRouteInfo,
    EmptyAbleRouteInfo,
    ValueRouteInfo<boolean[]>,
    BaseRouteInfo {
  type: 'boolean[]';
}

export interface RouteInfoDateArray
  extends NullableRouteInfo,
    EmptyAbleRouteInfo,
    ValueRouteInfo<Date[]>,
    BaseRouteInfo {
  type: 'Date[]';
}

export type RouteInfo =
  | RouteInfoCallback
  | RouteInfoString
  | RouteInfoNumber
  | RouteInfoBoolean
  | RouteInfoDate
  | RouteInfoStringArray
  | RouteInfoNumberArray
  | RouteInfoBooleanArray
  | RouteInfoDateArray;

export type RouteInfos = { [key: string]: RouteInfo };
